import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion'
import CurrencyFormat from 'react-currency-format';

const CardContainer = styled.div`
  ${tw`pb-6 md:px-3 w-full md:w-1/2 lg:w-1/3 m-0 flex-grow`};
`
const Card = styled.div
  `
    ${tw`overflow-hidden shadow-lg w-full h-full`}
    border-radius:2rem;
  `

const ProductLink = styled(Link)`
  ${tw`flex flex-no-grow flex-col h-full`};
  color: inherit;
  text-decoration: inherit;
`

const ProductDetails = styled.div
  `
    ${tw`px-4 text-center p-4 flex flex-col justify-between flex-grow`}
  `

const ProductImage = styled.img
  `${tw`w-full mb-0 block`}`

const ProductName = styled.h3
  `${tw`mb-3`}`

const OldPrice = styled.div
  `${tw`line-through mb-0 text-sm`}`

const CurrentPrice = styled.div
  `${tw`text-red text-xl font-bold`}`

class ProductCard extends Component {
  render() {
    return (
      <CardContainer style={this.props.lgth=='full'?{width:'100%'}:{}}>
        <Card>
          <ProductLink
            to={this.props.slug}
            state={this.props.productDetail}
          >
            <ProductImage src={this.props.image.url} alt={this.props.image.alt}/>
            <ProductDetails>
              <ProductName>{this.props.productName}</ProductName>
              <div>
                {this.props.discountPrice === this.props.currentPrice ? <OldPrice style={{visibility:'hidden'}}>-</OldPrice> : 
                  <OldPrice>
                    <CurrencyFormat
                      value={this.props.currentPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'Rp '}
                    />
                  </OldPrice>
                }
                <CurrentPrice>
                  <CurrencyFormat
                    value={(this.props.discountPrice === this.props.currentPrice) ? this.props.currentPrice : this.props.discountPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                  />
                </CurrentPrice>
              </div>
            </ProductDetails>
          </ProductLink>
        </Card>
      </CardContainer>
    )
  }
}

export default ProductCard;